// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-preview-page-js": () => import("./../../../src/pages/preview-page.js" /* webpackChunkName: "component---src-pages-preview-page-js" */),
  "component---src-pages-preview-post-js": () => import("./../../../src/pages/preview-post.js" /* webpackChunkName: "component---src-pages-preview-post-js" */),
  "component---src-templates-booking-table-js": () => import("./../../../src/templates/booking-table.js" /* webpackChunkName: "component---src-templates-booking-table-js" */),
  "component---src-templates-list-category-js": () => import("./../../../src/templates/list-category.js" /* webpackChunkName: "component---src-templates-list-category-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-post-js": () => import("./../../../src/templates/post.js" /* webpackChunkName: "component---src-templates-post-js" */),
  "component---src-templates-subscribe-js": () => import("./../../../src/templates/subscribe.js" /* webpackChunkName: "component---src-templates-subscribe-js" */)
}

